@import "bootstrap";
@import "main";

@font-face {
    font-family: 'Boxed';
    src: url('../fonts/tipo_pepel_-_boxedheavy-webfont.eot');
    src: url('../fonts/tipo_pepel_-_boxedheavy-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/tipo_pepel_-_boxedheavy-webfont.woff2') format('woff2'), url('../fonts/tipo_pepel_-_boxedheavy-webfont.woff') format('woff'), url('../fonts/tipo_pepel_-_boxedheavy-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Boxed';
    src: url('../fonts/tipo_pepel_-_boxedheavy-italic-webfont.eot');
    src: url('../fonts/tipo_pepel_-_boxedheavy-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/tipo_pepel_-_boxedheavy-italic-webfont.woff2') format('woff2'), url('../fonts/tipo_pepel_-_boxedheavy-italic-webfont.woff') format('woff'), url('../fonts/tipo_pepel_-_boxedheavy-italic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Boxed';
    src: url('../fonts/tipo_pepel_-_boxedlight-webfont.eot');
    src: url('../fonts/tipo_pepel_-_boxedlight-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/tipo_pepel_-_boxedlight-webfont.woff2') format('woff2'), url('../fonts/tipo_pepel_-_boxedlight-webfont.woff') format('woff'), url('../fonts/tipo_pepel_-_boxedlight-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Boxed';
    src: url('../fonts/tipo_pepel_-_boxedlight-italic-webfont.eot');
    src: url('../fonts/tipo_pepel_-_boxedlight-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/tipo_pepel_-_boxedlight-italic-webfont.woff2') format('woff2'), url('../fonts/tipo_pepel_-_boxedlight-italic-webfont.woff') format('woff'), url('../fonts/tipo_pepel_-_boxedlight-italic-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

test {
    background: #ffffff;
}

h2.h2-middle {
    padding-top: 5px;
}

p a {
    color: #e31d1a;
    text-decoration: underline;
}
p a:hover {
    text-decoration: none;
}
.btn {
    display: block;
    background: #16a0a7;
    padding: 6px 24px;
    border: 0px solid #16a0a7;
    width: 120px;
    /*margin: 0 auto;*/
}
.btn:hover, .btn:focus {
    display: block;
    background: #2a2a2a;
    padding: 6px 24px;
    border: 0px solid #16a0a7;
    width: 120px;
    /*margin: 0 auto;*/
}
.btn:hover {
    display: block;
    background: #2a2a2a;
    padding: 6px 24px;
    border: 0px solid #16a0a7;
    width: 120px;
    /*margin: 0 auto;*/
}
.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none;
    background: #2a2a2a;
}
input[type="text"].form-control, input[type="text"].form-control:focus {
    background: #eaede6;
    border: 3px solid #16a0a7;
    border-radius: 4px;
    color: #222;
    max-width: 240px;
    width: 100%;
}

select {
    background-color: #eaede6 !important;
    border: 3px solid #16a0a7 !important;
    border-radius: 0px !important;
    color: #222;
    max-width: 240px;
    width: 100%;
    margin-bottom: 5px;
}

select option {
    background-color: #eaede6;
}

select option:not(:checked) {
    background-color: #eaede6;
}

textarea:focus, input[type="text"]:focus, input[type="email"]:focus {
    border-color: #16a0a7 !important;
}
.form-control::-webkit-input-placeholder {
    color: #202020;
}

.form-control:-moz-placeholder {
    color: #202020;
}

.form-control::-moz-placeholder {
    color: #202020;
}

.form-control:-ms-input-placeholder {
    color: #202020;
}

.radio, .checkbox {
    margin-top: 0;
}

.image img {
    width: 40px;
}
.cart-image img {
    padding: 25px;
}
.spacer25 {
    height: 25px;
}
.spacer50 {
    height: 50px;
}
.spacer75 { height: 75px; }
.space-left { margin-left: 70px; }
.space-left50 {
    margin-left: 50px;
}

.phone {
    background: url(../images/ico_adv_phone_150x150.png) no-repeat;
    background-size: 30px 50px;
    margin: 0px;
}
.calendar {
    width: 100%;
    height: 53px;
    background: url(../images/ico_adv_calendar_150x150.png) no-repeat;
    background-size: 50px 53px;
    background-position: center right;
    margin: 0px;
}
.calendar-right {
    width: 100%;
    height: 53px;
    background: url(../images/ico_adv_calendar_150x150.png) no-repeat;
    background-size: 50px 53px;
    background-position: center left;
    margin: 0px;
}
.box-number {
    padding: 10px 30px;
    border: 3px solid #e31d1a;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    max-width: 300px;
    width: auto;
    margin: 0 auto;
}
.ok {
    font-size: 40px;
    color: #46bdbf;
    padding-right: 25px;
}
.vcenter {
    vertical-align: middle;
}
.input-validation-text {
    color: #e31d1a;
    font-weight: normal;
    margin: 25px 0 0px 0;
    display: block;
    line-height: 50px;
}
.info-red {
    display: block;
    width: 50px;
    height: 50px;
    font: 300 30px/50px 'Boxed', helvetica, arial, sans-serif;
    text-align: center;
    border-radius: 50%;
    background: transparent;
    color: #e31d1a;
    margin: 0px 20px 20px 0;
    float: left;
    border: 1px solid #e31d1a;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    background: #16a0a7 !important;
}

.checkbox {
    margin-top: 10px;
}
.checkbox label {
    padding-top: 1px;
}
label a { 
    text-decoration: underline; 
}
label a:hover {
    text-decoration: none;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
    margin-left: 0px;
}


