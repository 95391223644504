﻿
body {
    background: url(../images/header-bg.png) repeat;
}

body.mceContentBody {
    padding: 15px;
    background: #eaede6;
}

h1 {
    font: 600 28px/38px 'Boxed', arial, sans-serif;
    margin: 0 0 20px 0;
    color: #e31d1a;
    text-transform: uppercase;
}

h1.with-line {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e1d8;
}

h2 {
    font: 600 24px/30px 'Boxed', arial, sans-serif;
    color: #1d1d1b;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

h3 {
    font: 600 20px/30px 'Boxed', arial, sans-serif;
    color: #1d1d1b;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

h4 {
    font: 300 20px/30px 'Boxed', arial, sans-serif;
    color: #1d1d1b;
    margin: 0 0 5px 0;
    text-transform: uppercase;
}

h5 {
    font: 300 18px/30px 'Boxed', arial, sans-serif;
    color: #e31d1a;
    margin: 0 0 5px 0;
    text-transform: uppercase;
}

p {
    font: 400 16px/24px 'Open Sans', arial, sans-serif;
    color: #202020;
    margin: 0 0 15px 0;
}

p a {
    color: #e31d1a;
    text-decoration: underline;
}

p a:hover, p a:focus {
    color: #e31d1a;
    text-decoration: none;
}

p.lead {
    font: 300 28px/40px 'Boxed', arial, sans-serif;
    color: #1d1d1b;
    margin: 0 0 25px 0;
}

p.lead.with-line {
    padding-bottom: 35px;
    margin-bottom: 50px;
    border-bottom: 1px solid #e0e1d8;
}

p.source {
    text-align: right;
    font: 300 13px/18px 'Open Sans', arial, sans-serif;
    color: #606060;
}

table {
    width: 100%;
    padding: 0;
    margin: 20px 0;
}

table td {
    border-top: 1px solid #d6d7cc;
    border-bottom: 1px solid #d6d7cc;
    padding: 10px 15px;
    text-align: center;
}

table td:first-child, table th:first-child {
    text-align: left;
}

table td:last-child, table th:last-child {
    text-align: right;
}

table thead td {
    background: #e6e8e0;
}

table.vertical-columns tr td {
    padding: 20px 10px;
}

table.vertical-columns tr td:nth-child(odd) {
    background: #e6e8e0;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
    color: inherit;
    text-decoration: none;
}

*:focus {
    outline: none;
}

a.more {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

hr {
    border-color: #e0e1d8;
}

hr.with-arrow {
    padding: 0;
    border: none;
    border-top: 1px solid #FFF;
    color: #FFF;
    text-align: center;
    cursor: pointer;
}

hr.with-arrow:after {
    content: "";
    display: inline-block;
    width: 72px;
    height: 72px;
    background: url(../images/ico-arr-down.png) center center no-repeat;
    position: relative;
    top: -35px;
    left: -35px;
    font-size: 1.5em;
    padding: 0 0.25em;
}

hr.with-arrow:before {
    content: "";
    display: inline-block;
    width: 72px;
    height: 72px;
    background: url(../images/header-bg.png) repeat;
    position: relative;
    top: -35px;
    left: 37px;
    padding: 0 0.25em;
    border-radius: 72px;
}

a.with-red-arrow {
    display: block;
    position: relative;
    padding: 0;
    border-top: 1px solid #e31d1a;
    text-align: center;
    cursor: pointer;
    margin: 30px 0 70px 0;
}

a.with-red-arrow img {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -27px;
}

a.with-red-arrow img.up {
    display: none;
}

a.with-red-arrow.up img.up {
    display: block;
}

a.with-red-arrow.up img.down {
    display: none;
}

hr.with-red-arrow:after {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #EAEDE6 url(../images/ico-arr-red-down.png) center center no-repeat;
    position: relative;
    top: -25px;
    left: -25px;
    font-size: 1.5em;
    padding: 0 0.25em;
}

hr.with-red-arrow.up:after {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #EAEDE6 url(../images/ico-arr-red-up.png) center center no-repeat;
    position: relative;
    top: -26px;
    left: -25px;
    font-size: 1.5em;
    padding: 0 0.25em;
}

.border-between > [class*='col-']:before {
    background: #e0e1d8;
    bottom: 0;
    content: " ";
    left: 0;
    position: absolute;
    width: 1px;
    top: 0;
}

.border-between > [class*='col-']:first-child:before {
    display: none;
}

.grey-box {
    background: #e6e8e0;
    padding: 30px 20px;
    margin: 30px 0;
}

.grey-box ul {
    margin: 0;
}

.phone {
    padding: 0 0 50px 0;
    margin: 0 0 50px 0;
    border-bottom: 1px solid #d6d7cc;
}

.phone:last-child {
    border-bottom: none;
}

.phone img {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 350ms ease-in;
    -moz-transition: all 350ms ease-in;
    -ms-transition: all 350ms ease-in;
    -o-transition: all 350ms ease-in;
    transition: all 350ms ease-in;
}

.phone img:hover {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

.black-number {
    display: block;
    width: 50px;
    height: 50px;
    font: 300 30px/50px 'Boxed', helvetica, arial, sans-serif;
    text-align: center;
    border-radius: 50%;
    background: #2a2a2a;
    color: #FFF;
    margin: -10px 20px 20px 0;
    float: left;
}

.featured-three-row .row {
    display: block;
    padding: 20px 0;
    border-top: 1px solid #e0e1d8;
    border-bottom: 1px solid #e0e1d8;
    text-align: center;
}

.featured-three-row .img {
    height: 100px;
    line-height: 100px;
}

.featured-three-row p {
    font-weight: 600;
    margin: 0;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    color: #FFF;
    font: 600 14px/normal 'Open Sans', arial, sans-serif;
    background: #1d1d1b;
    border: 2px solid #1d1d1b;
    border-radius: 3px;
    transition: all 0.4s;
}

.btn:hover {
    background: #060606;
    border-color: #060606;
    color: #FFF;
}

.btn.red {
    background: #e31d1a;
    border-color: #e31d1a;
}

.btn.red:hover {
    background: #b41715;
    border-color: #b41715;
}

.btn.info {
    font: 400 16px/normal 'Open Sans', arial, sans-serif;
    color: #e31d1a;
    padding: 0;
    border: none;
    background: none;
}

.btn.info:hover {
    color: #b41715;
}

.btn.info:before {
    display: inline-block;
    content: '';
    width: 50px;
    height: 50px;
    background: url(../images/ico-info.png) no-repeat;
    margin: 0 15px -19px 0;
    transition: all 0.4s;
}

.btn.info:hover:before {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
}

.button > span {
    float: left;
    margin: 3px 6px 0 0;
}

textarea, input[type="text"], input[type="email"] {
    border-radius: 0 !important;
    outline: none !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
}

textarea:focus, input[type="text"]:focus, input[type="email"]:focus {
    outline: none !important;
    border-color: #e2e2e2 !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
}

.full-bg-image {
    position: relative;
    width: 100%;
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.container {
    width: auto;
    max-width: 1500px;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

header {
    height: 130px;
}

.floating header .logo {
    display: none;
}

header .logo img {
    max-width: 222px;
    margin-top: 15px;
}

header .telekom {
    float: right;
    margin-top: 40px;
    min-height: 49px;
}

header ul {
    list-style: none;
    margin: 55px 0 0 0;
    padding: 0;
    width: 100%;
    text-align: center;
}

header ul.right {
    text-align: right;
}

header ul li {
    display: inline-block;
    font: 600 13px/normal 'Boxed', helvetica, arial, sans-serif;
    color: #202020;
    margin: 0 10px;
    text-transform: uppercase;
}

@media (max-width: 1340px) {
    header ul li {
        float: left;
    }

    header ul li.break {
        clear: left;
    }
}

header ul li img {
    float: left;
    margin: 3px 6px 0 0;
}

header ul li a {
    text-transform: none;
    font: 400 13px/normal 'Open Sans', arial, sans-serif;
}

header ul li a:hover {
    text-decoration: underline;
}

header ul li.selected {
    font-weight: 600;
    text-transform: none;
}

@media (max-width: 992px) {

    header {
        height: 90px;
    }

    header .logo img {
        max-width: 120px;
        margin: 10px 0 0 0;
    }

    header ul {
        text-align: right;
        float: right;
        margin-top: 35px;
    }

    header ul li {
        float: none;
        margin: 0 5px;
    }
}

#menu {
    background: url(../images/menu-bg.png) repeat;
    padding: 20px 0;
    text-align: center;
}

.floating #menu {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    padding: 0;
}

#menu a.logo {
    margin: 0;
    float: left;
}

#menu a.logo img {
    width: 0;
    margin: 0 0 -9px 0;
    padding: 0;
    opacity: 0;
    transition: all 0.4s;
}

.floating #menu a.logo img {
    height: 68px;
    width: auto;
    opacity: 1;
}

#menu ul {
    width: 100%;
}

.floating #menu ul {
    margin-left: -112px;
    padding-top: 20px;
}

#menu ul li {
    display: inline-block;
    margin: 0 17px;
}

#menu ul li a {
    font: 300 15px/normal 'Boxed', helvetica, arial, sans-serif;
    color: #FFF;
    text-transform: uppercase;
    margin: 0;
}

#menu .menu-toggle {
    display: none;
}

@media (max-width: 992px) {
    #menu {
        padding: 0;
    }

    #menu .menu-toggle {
        display: block;
        text-align: center;
        margin: 20px auto;
    }

    #menu ul {
        display: none;
        padding: 5px 0 20px 0;
        margin: 0;
    }

    #menu ul li {
        display: block;
        padding: 13px 0;
        margin: 0;
    }
}

#menu ul li a:hover, #menu ul li.selected a {
    color: #2a2a2a;
}

#top-content .mobile-slider {
    display: block;
    background: #eaede6;
    padding: 20px 0 0 0;
    text-align: center;
}

#top-content .mobile-slider .mobile-img {
    display: block;
    text-align: center;
    margin-bottom: 30px;
}

#top-content .mobile-slider .btn {
    display: inline-block;
}

#top-content .mobile-slider h2 {
    font: 200 24px/30px 'Boxed', helvetica, arial, sans-serif;
    color: #2a2a2a;
    text-align: left;
    margin: 20px 0 25px 0;
}

#top-content .mobile-slider h2 span {
    font-weight: 600;
}

#top-content .mobile-slider .next-slide {
    margin: 20px 0 0 0;
}

.content {
    background: #eaede6;
    padding: 70px 0 50px 0;
}

.content.transparent {
    background: transparent;
}

.content ul, body.mceContentBody ul {
    margin-bottom: 15px;
}

.content li, body.mceContentBody li {
    padding-left: 0;
    text-indent: -15px;
    font: 300 15px/24px 'Open Sans', arial, sans-serif;
    color: #202020;
    margin: 0 0 7px 35px;
}

.content li:before, body.mceContentBody li:before {
    content: "•";
    color: #e31d1a;
    padding-right: 9px;
}

.content.transparent li:before {
    color: #FFF;
}

.content li ul, body.mceContentBody li ul {
    margin: 7px 0 0 -20px;
}

.content li ul li:before, body.mceContentBody li ul li:before {
    color: #1d1d1b;
}

#top-content .slider {
    display: block;
    position: relative;
    background: #eaede6;
    padding: 0 0 40px 0;
    overflow: hidden;
    transition: height 0.3s;
    /*height: 550px;*/
}

#top-content .slider #cycle-loader {
    position: absolute;
    margin: 200px auto;
}

#top-content .slider #maximage {
    position: relative !important;
    width: 100%;
    z-index: 0;
}

#top-content .slider .in-slide-content {
    -moz-opacity: 0;
    opacity: 0;
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all 350ms ease-in;
    -moz-transition: all 350ms ease-in;
    -ms-transition: all 350ms ease-in;
    -o-transition: all 350ms ease-in;
    transition: all 350ms ease-in;
}

#top-content .slider .in-slide-content div {
    display: block;
    /*float: left;*/
    /*width: 33.33333%;*/
}

#top-content .slider .in-slide-content.visible {
    opacity: 1;
    -moz-opacity: 1;
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

#top-content .slider .next-slide {
    display: block;
    text-align: center;
    margin: 40px auto 0 auto;
}

#top-content .slider .pager-container {
    display: block;
}

#top-content .slider #pager a {
    display: block;
    width: 15px;
    height: 15px;
    text-indent: -999em;
    background: transparent;
    border-radius: 15px; /* must be the same as width and height */
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    margin: 0 auto 15px auto;
    border: 1px solid #2a2a2a;
}

@media (max-width: 1200px) {
    #top-content .slider .pager-container {
        text-align: center;
    }

    #top-content .slider #pager {
        text-align: center;
    }

    #top-content .slider #pager a {
        display: inline-block;
        margin: 0 5px;
    }

    #top-content .slider .next-slide {
        display: none;
    }
}

#top-content .slider #pager a.activeSlide, #top-content .slider #pager a:hover {
    background: #2a2a2a; /* color when active */
}

#top-content .slider h2 {
    font: 200 28px/38px 'Boxed', helvetica, arial, sans-serif;
    color: #2a2a2a;
    margin: 30% 0 0 0;
    width: 95%;
    text-transform: none;
}

#top-content .slider h2.big {
    font-size: 35px;
}

#top-content .slider h2 span {
    font-weight: 600;
    text-transform: uppercase;
}

#top-content .slider .img-responsive {
    max-width: 100%;
}

.bar:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 72px;
    background: url(../images/slider-bottom.png) repeat-x;
    width: 100%;
}

.bar {
    position: relative;
    padding: 120px 0 50px 0;
    text-align: center;
}

.bar.empty {
    padding: 50px 0;
}

#top-content h3, #top-content a.btn {
    display: inline-block;
    margin: 0 20px;
}

#top-content h3 {
    font: 600 28px/28px 'Boxed', helvetica, arial, sans-serif;
    color: #FFF;
    text-transform: uppercase;
}

#top-content a.btn {
    margin-top: -12px;
    margin-right: 0;
}

@media (max-width: 992px) {
    #top-content h3 {
        display: block;
        margin-bottom: 30px;
    }

    #top-content a.btn {
        margin: 0 5px 5px 0;
    }
}

#exposed-products {
    margin: 0 0 50px 0;
}

@media (max-width: 470px) {
    #exposed-products img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 768px) {
    .owl-buttons div {
        display: none !important;
    }
}

.owl-buttons div {
    position: absolute;
    width: 27px;
    height: 100px;
    opacity: 0.6;
    top: 100px;
    background: none;
    border: none;
    text-indent: -9999px;
    transition: all 0.4s;
}

.owl-prev {
    left: 10%;
    background: transparent url(../images/ico-arrow-left-big.png) no-repeat !important;
}

.owl-next {
    right: 10%;
    background: transparent url(../images/ico-arrow-right-big.png) no-repeat !important;
}

#advantages {
    padding: 50px 0 30px 0;
    text-align: center;
}

#advantages h2 {
    font: 600 24px/24px 'Boxed', helvetica, arial, sans-serif;
    color: #1d1d1b;
    margin: 0 0 50px 0;
    text-transform: uppercase;
}

#advantages figure {
    width: 80%;
    margin: 0 auto 40px auto;
}

#advantages figure img {
    margin-bottom: 20px;
}

#advantages figure p {
    font: 400 18px/24px 'Open Sans', helvetica, arial, sans-serif;
    color: #202020;
}

@media (max-width: 992px) {
    #advantages figure p {
        font: 400 16px/22px 'Open Sans', helvetica, arial, sans-serif;
    }
}

footer {
    background: #07414d;
    padding: 15px 0 10px 0;
    text-align: center;
}

footer .left {
    text-align: left;
}

footer .right {
    text-align: right;
}

footer ul {
    padding: 20px 0;
}

footer ul li {
    display: inline-block;
    font: 400 13px/13px 'Open Sans', helvetica, arial, sans-serif;
    color: #46bdbf;
}

footer ul li:after {
    display: inline-block;
    content: '';
    width: 2px;
    height: 14px;
    background: #122f34;
    margin: 0 7px -4px 7px;
}

footer .spar-logo {
    position: absolute;
    right: 20px;
    top: 20px;
}

@media (max-width: 992px) {

    footer .spar-logo {
        top: auto;
        bottom: -20px;
        right: auto;
        left: 50%;
        margin-left: -45px;
    }

    footer ul {
        margin: 0 0 20px 0;
    }

    footer ul li {
        display: block;
        font: 400 13px/22px 'Open Sans', helvetica, arial, sans-serif;
        color: #46bdbf;
        text-align: center;
        margin: 0 0 6px 0;
    }

    footer ul li:after {
        display: none;
    }

    footer .left .go-top {
        text-align: center;
        margin: 20px auto;
    }
}

footer ul li:last-child:after {
    display: none;
}

footer ul li a {
    font: 400 13px/13px 'Open Sans', helvetica, arial, sans-serif;
    color: #46bdbf;
}

footer ul li a:hover {
    text-decoration: underline;
}
